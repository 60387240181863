@use "@general/styles/bootstrap/boostrap_configuration" as bst;
@use "sass:map";

.top-menu {
    a{
        text-decoration: none;
        color: inherit;
    }
    ul {
        list-style-type: none;
        padding: 0;
    }

    float: right;

    .first-level {
        margin-bottom: 0;
        margin-top: 5px;

        >li {
            display: inline-block;
            display: inline-block;
            height: 50px;
            padding-top: 10px;
            position: relative;
            margin-left: map.get(bst.$spacers, 4);

            >.title {
                padding: 9px 15px;
                cursor: pointer;
                border-radius: bst.$border-radius;

                &:hover {
                    background-color: bst.$primary;
                    color: bst.$white;
                }
            }

            .second-level {
                display: none;
            }

            &.opened {
                >.title {
                    background-color: bst.$primary;
                    color: bst.$white;
                }

                .second-level {
                    display: block;
                }
            }
        }

    }

    .second-level {
        display: block;
        position: fixed;
        background-color: bst.$white;
        margin: 0;
        top: 75px;
        left: 0;
        width: 100vw;
        border-top: 5px solid bst.$primary;
        padding: map.get(bst.$spacers, 4) 0;

        >ul {
            display: flex;
            padding: map.get(bst.$spacers, 2);
            max-width: map.get(bst.$container-max-widths, 'xxl');
            margin: 0 auto;

            >li {

                color: bst.$gray-600;
                width: 23%;
                margin-right: 2%;

                >.title {
                    font-weight: bold;
                    color: bst.$dark;
                    font-size: 1.4em;
                    display: inline-block;
                    margin-bottom: map.get(bst.$spacers, 2);
                }

                a {
                    line-height: 1.8em;

                    &:hover {
                        color: bst.$dark;
                    }
                }

                &.last {
                    border-right: none;
                }
            }
        }
    }
    @include bst.media-breakpoint-down(xl) {
        .second-level {    
            >ul {    
                >li {
                    width: 30%;
                }
            }
        }
    }
}