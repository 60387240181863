@use "@general/styles/bootstrap/boostrap_configuration" as bst;
@use "@general/styles/components/variables" as variable;
@use "sass:map";

.mobile-menu-button {
    float: right;
    font-size: 1.5em;
    margin-top: 9px;
    margin-left: 17px;
    cursor: pointer;
}

.mobile-menu {
    width: 0px;
    transition: width 1s;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 70px;
    left: 0;
    height: calc(100vh - 70px);
    background-color: bst.$white;
    z-index: variable.$z_inex_level_2;
    border-top: 5px solid bst.$primary;
    a{
        text-decoration: none;
        color: inherit;
    }
    &.opened {
        width: 100vw;

    }

    .close {
        font-size: map.get(bst.$display-font-sizes, 6);
        color: bst.$gray-400;
        position: absolute;
        top: map.get(bst.$spacers, 4);
        right: map.get(bst.$spacers, 4);
        cursor: pointer;
        display: block;
    }

    .mobile-menu-wrapper {
        padding: map.get(bst.$spacers, 4);
        width: 100vw;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    .title {
        font-size: bst.$legend-font-size;
        color: bst.$gray-700;
        border-bottom: 1px solid bst.$gray-300;
        padding: map.get(bst.$spacers, 3) 0;
        cursor: pointer;
        display: block;
    }

    .third-level {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s;
        padding-left: map.get(bst.$spacers, 3);
        a{
            padding: map.get(bst.$spacers, 3) 0;
            border-bottom: 1px solid bst.$gray-300;
            display: block;
        }
    }

    .second-level {
        i {
            float: right;
        }

        .icon-angle-regular-up {
            display: none;
        }

        .opened {
            .icon-angle-regular-up {
                display: inline-block;
            }

            .icon-angle-regular-down {
                display: none;
            }

            .third-level {
                max-height: 400px;
            }
        }
    }
}